import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import { renderIcon } from '../../utils/icons';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import ThemedBackground from '../ThemedBackground';

const WhyShould: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    whyShouldSection: { title, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  return (
    <ThemedBackground as="section">
      <div style={{ backgroundColor: '#181818' }} id="why">
        <Container style={{ paddingBottom: 100 }}>
          <Col>
            {isMobile ? (
              <Title style={{ fontSize: 38 }}>{title}</Title>
            ) : (
              <CaptionedTitle as="h3">{title}</CaptionedTitle>
            )}
          </Col>
          <Row style={{ padding: 5, justifyContent: 'start', marginTop: 20 }}>
            {items.map((item, index) => {
              return (
                <Col key={`item-${index}`} md={2.5} lg={3.7} xl={3.7} style={{ margin: 5, padding: 15 }}>
                  <IconContainer>{renderIcon(item.icon, false)}</IconContainer>
                  <Title>{item.title}</Title>
                  <Markdown className="description-gray" content={item.description} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </ThemedBackground>
  );
};

export default WhyShould;

const IconContainer = styled.div`
  height: 25px;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 10px;
  font-weight: medium;
`;

const query = graphql`
  query {
    data: allSwapPageYaml {
      edges {
        node {
          whyShouldSection {
            title
            items {
              title
              description
              icon
            }
          }
        }
      }
    }
  }
`;
