import { useState } from 'react';
import styled from 'styled-components';

import deviceBreakPoints from '../styles/breakpoints';

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
`;

const TabButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#181818' : 'transparent')};
  color: ${(props) => (props.active ? '#fff' : '#333')};
  border: 1px solid ${(props) => (props.active ? '#181818' : 'transparent')};
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #181818;
    color: #fff;
  }
`;

const TabContent = styled.div<{ active: boolean }>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  background-color: #181818;
  padding: 20px;

  @media ${deviceBreakPoints.mobile} {
    padding: 20px 10px;
  }
`;

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <TabButton key={index} active={index === activeTab} onClick={() => handleTabClick(index)}>
            {tab.title}
          </TabButton>
        ))}
      </TabsContainer>
      <TabContent active={tabs[activeTab].content}>{tabs[activeTab].content}</TabContent>
    </div>
  );
};

export default Tabs;
