import { graphql, useStaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import Page from '../components/Page';
import FaqAboutSwap from '../components/swap-page/FaqAboutSwap';
import HowBuySell from '../components/swap-page/HowBuySell';
import ImageSection from '../components/swap-page/ImageSection';
import SwapPageHeader from '../components/swap-page/SwapPageHeader';
import WhyShould from '../components/swap-page/WhyShould';
import YouNeedHelp from '../components/swap-page/YouNeedHelp';
import { darkTheme } from '../styles/themes';

const SwapPage = () => {
  const { data } = useStaticQuery(query);
  const { seo } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={darkTheme}>
        <SwapPageHeader />
        <WhyShould />
        <HowBuySell />
        <ImageSection />
        <YouNeedHelp />
        <FaqAboutSwap />
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allSwapPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
        }
      }
    }
  }
`;

export default SwapPage;
