import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import CashGateway from '../../images/svgs/swap/cash-gateway.svg';
import deviceBreakPoints from '../../styles/breakpoints';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';
import { BlockRow } from '../Rows';

const Header: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, description }
  } = data.edges[0].node;

  return (
    <PageHeaderStyled>
      <Col>
        <CaptionedTitle as="h2" caption={caption}>
          {title}
        </CaptionedTitle>
        <BlockRow style={{ marginTop: 30 }}>
          <Col lg={7}>
            <MarkdownStyled className="description-gray" content={description} />
            <ButtonContainer>
              <ButtonFind disabled>Find a Swapbox</ButtonFind>
              {/* Not implemented yet */}
              {/* <ButtonTry disabled>Try the interface</ButtonTry> */}
            </ButtonContainer>
          </Col>
        </BlockRow>
      </Col>
      <ImageContainer>
        <CashGatewayStyled />
      </ImageContainer>
    </PageHeaderStyled>
  );
};

const PageHeaderStyled = styled(PageHeader)`
  padding-bottom: 0px;
  background-color: #181818;
  @media ${deviceBreakPoints.mobile} {
    padding-bottom: 60px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px;
`;

const ButtonFind = styled.button`
  background-color: #d6d6d6;
  border: 1px solid #292929;
  color: #292929;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  z-index: 1;

  @media ${deviceBreakPoints.mobile} {
    padding: 10px 20px;
  }

  &:hover {
    background-color: #ffffff;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ImageContainer = styled(Col)`
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-bottom: -140px;
  @media ${deviceBreakPoints.mobile} {
    margin-bottom: 0px;
  }
  @media ${deviceBreakPoints.tablet} {
    margin-bottom: 0px;
  }
`;

const CashGatewayStyled = styled(CashGateway)`
  width: 100%;
  height: 80%;
  position: relative;
  top: -160px;

  & rect {
    transform: translate(120px, 0px);
    @media ${deviceBreakPoints.mobile} {
      transform: translate(0, 0);
    }
  }

  @media ${deviceBreakPoints.mobile} {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0px;
    margin-top: 20px;
  }

  @media ${deviceBreakPoints.tablet} {
    width: 100%;
    height: 80%;
    top: 0px;
    margin-top: 20px;
  }
`;

// const ButtonTry = styled.button`
//   background-color: #292929;
//   border: 1px solid #292929;
//   color: #fff;
//   font-size: 14px;
//   font-weight: 500;
//   padding: 12px 20px;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   margin-left: 15px;
//   cursor: pointer;
//   z-index: 1;

//   @media ${deviceBreakPoints.mobile} {
//     padding: 10px 15px;
//   }

//   &:hover {
//     background-color: #3c3c3c;
//   }

//   &:disabled {
//     cursor: not-allowed;
//   }
// `;

const MarkdownStyled = styled(Markdown)`
  & p {
    line-height: 25.5px;
    width: 600px;

    @media ${deviceBreakPoints.mobile} {
      width: 100%;
    }
  }
`;

const query = graphql`
  query {
    data: allSwapPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
        }
      }
    }
  }
`;

export default Header;
