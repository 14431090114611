import { graphql, navigate, useStaticQuery } from 'gatsby';
import Lottie from 'lottie-react';
import { FC } from 'react';
import { Col, Container, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

// animations
import Buy1 from '../../animation/swap/buy_1.json';
import Buy2 from '../../animation/swap/buy_2.json';
import Buy3 from '../../animation/swap/buy_3.json';
import Buy4 from '../../animation/swap/buy_4.json';
import Sell1 from '../../animation/swap/sell_1.json';
import Sell2 from '../../animation/swap/sell_2.json';
import Sell3 from '../../animation/swap/sell_3.json';
import Sell4 from '../../animation/swap/sell_4.json';
// components
import useWindowSize from '../../hooks/useWindowSize';
import deviceBreakPoints from '../../styles/breakpoints';
import Heading from '../Heading';
import Markdown from '../Markdown';
import Tabs from '../Tabs';
import ThemedBackground from '../ThemedBackground';

type Animation = {
  id: string;
  animation: unknown;
  title: string;
  description: string;
};

const buyAnimations = [
  { id: 'buy_1', animation: Buy1 },
  { id: 'buy_2', animation: Buy2 },
  { id: 'buy_3', animation: Buy3 },
  { id: 'buy_4', animation: Buy4 }
];

const sellAnimations = [
  { id: 'sell_1', animation: Sell1 },
  { id: 'sell_2', animation: Sell2 },
  { id: 'sell_3', animation: Sell3 },
  { id: 'sell_4', animation: Sell4 }
];

const HowBuySell: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    howBuySellSection: { title, description, items }
  } = data.edges[0].node;
  const { isMobile } = useWindowSize();

  const tabs = items.map((item) => {
    return {
      title: item.title,
      content: (
        <Row>
          {item.items.map((item1: Animation, index1: number) => {
            const codeAnimation = item1.animation;
            const buyAnimation = buyAnimations.find((animation) => animation.id === codeAnimation);
            const sellAnimation = sellAnimations.find((animation) => animation.id === codeAnimation);
            const animation = item.title.includes('Buy') ? buyAnimation : sellAnimation;
            return (
              <Col sm={4} md={2} lg={3} xl={3} key={`item-${index1}`}>
                <Lottie animationData={animation?.animation} style={{ marginTop: 10, marginBottom: 5 }} />
                <Heading element="h5">{item1.title}</Heading>
                <Markdown className="description-gray" content={item1.description} />
              </Col>
            );
          })}
        </Row>
      )
    };
  });

  return (
    <ThemedBackground as="section">
      <Container>
        <Col>
          {isMobile ? (
            <Title style={{ fontSize: 38 }}>{title}</Title>
          ) : (
            <TitleContainer>
              <HeadingStyled element="h3">{title}</HeadingStyled>
              <MarkdownStyled className="description-gray" content={description} />
            </TitleContainer>
          )}
        </Col>
        <Col style={{ marginTop: 50 }}>
          <Tabs tabs={tabs} />
        </Col>
        <BottomSection>
          <Heading element="h4">Not sure how to use a Swapbox to buy or sell crypto?</Heading>
          <ButtonStyled
            onClick={() => {
              navigate('/docs/nav.05.users/');
            }}
          >
            Visit the user documentation
          </ButtonStyled>
        </BottomSection>
      </Container>
    </ThemedBackground>
  );
};

export default HowBuySell;

const TitleContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.text.tertiary};
  margin-top: var(--spacing-6);
  display: flex;
  padding-top: var(--spacing-3);
`;

const HeadingStyled = styled(Heading)`
  width: 35%;
`;

const MarkdownStyled = styled(Markdown)`
  width: 60%;
  padding-left: var(--spacing-6);
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 1.33;
  margin-bottom: 10px;
  font-weight: medium;
`;

const BottomSection = styled(Col)`
  align-items: center;
  padding: 50px;
  @media ${deviceBreakPoints.mobile} {
    padding: 50px 20px;
    text-align: center;
  }
`;

const ButtonStyled = styled.button`
  background-color: #292929;
  border: 1px solid #292929;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 60px;
  margin-top: 40px;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${deviceBreakPoints.mobile} {
    padding: 10px 34px;
  }

  &:hover {
    background-color: #3c3c3c;
  }
`;

const query = graphql`
  query {
    data: allSwapPageYaml {
      edges {
        node {
          howBuySellSection {
            title
            description
            items {
              title
              items {
                title
                description
                animation
              }
            }
          }
        }
      }
    }
  }
`;
