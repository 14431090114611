import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import { Col, Container } from 'react-awesome-styled-grid';

import ThemedBackground from '../ThemedBackground';

const ImageSection: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    imageSection: { image }
  } = data.edges[0].node;

  return (
    <ThemedBackground as="section">
      <Container>
        <Col>
          <GatsbyImage style={{ marginTop: 50 }} image={image.childImageSharp.gatsbyImageData} alt="image-swap" />
        </Col>
      </Container>
    </ThemedBackground>
  );
};

export default ImageSection;

const query = graphql`
  query {
    data: allSwapPageYaml {
      edges {
        node {
          imageSection {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
              }
            }
          }
        }
      }
    }
  }
`;
